'use strict'

require('./common/commons')
import DemilieCanvasLoader from './common/canvas-loader'
import DemilieObjectAnimator from './common/obj-animator'
import DemilieObjectCircleAnimator from './common/obj-circle-animator'

class DemilieAnimation {
    constructor(el, name, count) {
        this.el = el
        this.name = name
        this.count = count

        this.images = []
        this.first = null
        this.initImages()

        this.current = 0
        this.frame = document.createElement('div')
        this.frame.setAttribute('style', 'display: inline-block; position: relative;')

        this.canvas = document.createElement('canvas')
        this.ctx = this.canvas.getContext('2d')
        this.run()
    }

    zpad(str, len) {
        let s = str.toString()
        return (s.length > len) ?
            s
        :
            Array(len - s.length + 1).join('0') + s
        
    }

    createImage(i) {
        let im = document.createElement('canvas')
        im.setAttribute('data-img', this.name + '_' + this.zpad(i, 5) + '.jpg')
        im.setAttribute('data-mask', this.name + '_mask_' + this.zpad(i, 5) + '.png')
        return im
    }

    initImages() {
        this.first = this.createImage(0)
        for (let i = 1; i < this.count; i += 1) {
            let im = this.createImage(i)
            this.images.push(im)
        }
    }

    attachFirst() {
        this.frame.style.width = this.first.width + 'px'
        this.frame.style.height = this.first.height + 'px'
        this.canvas.width = this.first.width
        this.canvas.height = this.first.height
        this.frame.appendChild(this.canvas)
        this.el.appendChild(this.frame)
        this.ctx.drawImage(this.first, 0, 0, this.first.width, this.first.height)
        return true
    }

    attachImages() {
        Array.prototype.map.call(this.images, i => {
            i.setAttribute('style', 'position: absolute; top: 0; left: 0; opacity: 1; transform: translateZ(0); display: none;')
            this.frame.appendChild(i)
        })
    }

    play() {
        this.current += 1
        this.current %= this.count
        this.ctx.clearRect(0, 0, this.images[this.current].width, this.images[this.current].height)
        this.ctx.drawImage(this.images[this.current], 0, 0, this.images[this.current].width, this.images[this.current].height)
        // window.requestAnimationFrame(() => this.play())
        window.setTimeout(() => this.play(), 33)
    }

    run() {
        Promise.resolve()
            .then(() => new DemilieCanvasLoader(this.first).run() )
            .then(() => this.attachFirst())
            .then(() => {
                if (!demilie.device.isMobile) {
                    Promise.all( Array.prototype.map.call(this.images, i => { return new DemilieCanvasLoader(i).run() }) )
                        .then(() => {
                //             this.attachImages()
                            this.images.unshift(this.first)
                        })
                        .then(() => {
                            this.play()
                        })
                }
            })
            // .catch((err) => console.warn(err))
    }
}

class DemilieSparks {
    constructor(root) {
        this.canvas = root.querySelector('canvas')
        this.ctx = this.canvas.getContext('2d')
        this.x0 = this.canvas.width / 2
        this.y0 = this.canvas.height / 2

        this.ctx.fillStyle = 'orange'

        this.dur = 16
        this.count = 500
        this.g = 12

        this.parts = []
        for (let j = 0; j < this.count; j += 1) {
            let t = Math.random() * this.dur
            this.parts.push({
                v0: Math.random() * 100,
                a: Math.random() * Math.PI,
                i: t,
                t: t
            })
        }
    }

    run() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        for (let j = 0; j < this.parts.length; j += 1) {
            let t = this.parts[j].i
            let x = this.x0 + this.parts[j].v0 * t * Math.cos(this.parts[j].a)
            let y = this.y0 - (this.parts[j].v0 * t * Math.sin(this.parts[j].a) - this.g/2 * t * t)

            this.ctx.beginPath()
            this.ctx.arc(x, y, 2.5, 0, 2 * Math.PI, false)
            this.ctx.fill()
            this.ctx.closePath()

            this.parts[j].i += 0.2
            if (this.parts[j].i > this.dur) {
                this.parts[j].i = 0
            }
        }
        window.requestAnimationFrame(() => this.run())

    }
}

var demilie = window.demilie || {}

demilie.anim = [
    new DemilieAnimation(document.querySelector('.honey'), '/media/main/honey', 35),
    new DemilieAnimation(document.querySelector('.cream'), '/media/main/cream', 35)
]
demilie.sparks = new DemilieSparks(document.querySelector('.sparks')).run()

demilie.objs = Array.prototype.map.call(document.querySelectorAll('div[data-trajectory]'), i => new DemilieObjectAnimator(i).run())
demilie.cObjs = Array.prototype.map.call(document.querySelectorAll('div[data-circle]'), i => new DemilieObjectCircleAnimator(i).run())

demilie.showAllLink = function () {
    let l = document.querySelector('.all-link')
    let s = document.querySelector('body > .wrapper').offsetHeight - window.pageYOffset - window.innerHeight
    if (s < 30) {
        if (l.getAttribute('class').indexOf('on') === -1) {
            l.setAttribute('class', l.getAttribute('class') + ' on')
        }
        window.removeEventListener('scroll', demilie.showAllLink)
    }
}
window.addEventListener('scroll', demilie.showAllLink)

demilie.enterAllLink = function () {
    window.cancelAnimationFrame(allLinkPattReq)
    allLinkPatt.setAttribute('transform', 'translate(-' + allLinkPattPos + ', 0)')
    if (allLinkPattPos < 705) {
        allLinkPattPos += 38
        allLinkPattReq = window.requestAnimationFrame(demilie.enterAllLink)
    }
}

demilie.leaveAllLink = function () {
    window.cancelAnimationFrame(allLinkPattReq)
    allLinkPatt.setAttribute('transform', 'translate(-' + allLinkPattPos + ', 0)')
    if (allLinkPattPos > 0) {
        allLinkPattPos -= 38
        allLinkPattReq = window.requestAnimationFrame(demilie.leaveAllLink)
    }
}

let allLink = document.querySelector('.all-link')
let allLinkPatt = document.getElementById('pattImg')
let allLinkPattPos = 0
let allLinkPattReq = null
allLink.addEventListener('mouseenter', demilie.enterAllLink)
allLink.addEventListener('mouseleave', demilie.leaveAllLink)

window.demilie = demilie