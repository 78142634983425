'use strict'

import DemiliePreloader from './preloader'

export default class DemilieImageLoader {
    constructor(el) {
        this.el = el
        this.img = this.el.getAttribute('data-img')
        this.el.removeAttribute('data-img')
        this.mask = this.el.getAttribute('data-mask')
        this.el.removeAttribute('data-mask')

        this.canvas = document.createElement('canvas')
        this.ctx = this.canvas.getContext('2d')

        DemiliePreloader.registerItem(this.el, 'load')
    }

    setEmpty() {
        // console.log(this.img + ' set empty')
        this.el.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAtJREFUCB1j+M8AAAIBAQDFXxteAAAAAElFTkSuQmCC'
        return
    }

    getImage(src) {
        let im
        return new Promise(function (resolve, reject) {
            im = document.createElement('img')
            im.onload = () => resolve(im)
            im.onerror = reject
            im.src = src
        })
    }

    getData() {
        return this.canvas.toDataURL()
    }

    drawImg(im) {
        this.ctx.globalCompositeOperation = 'source-atop'
        this.ctx.drawImage(im, 0, 0, im.width, im.height)
        return true
    }

    drawMask(im) {
        this.canvas.width = im.width
        this.canvas.height = im.height
        this.ctx.drawImage(im, 0, 0, im.width, im.height)
        return true
    }

    drawData(data) {
        return new Promise((resolve, reject) => {
            this.el.onload = resolve
            this.el.onerror = reject
            this.el.src = data
            this.ctx = null
            this.canvas = null
        })
    }

    run() {
        return (!this.img || !this.mask) ?
            Promise.resolve(this.setEmpty())
        :
            this.getImage(this.mask)
                .then(resp => this.drawMask(resp))
                .then(() => this.getImage(this.img))
                .then(resp => this.drawImg(resp))
                .then(() => this.getData())
                .then(resp => this.drawData(resp))
                .then(null, () => this.setEmpty())
    }
}
