'use strict'

let instance = null

class DemiliePreloader {
    constructor() {
        if (!instance) {
            instance = this

            this.total = 0
            this.counter = 0
            this.items = []

            this.el = this.createElement()

            DocumentFragment.prototype.complete = () => {}
        }

        return instance
    }

    createElement() {
        let el = document.createElement('div')
        el.setAttribute('id', 'preloader')
        el.innerHTML = '<div class="logo"></div><div class="rotation"></div>'
        document.body.appendChild(el)

        return el
    }

    createEmitter() {
        return document.createDocumentFragment()
    }

    registerDoneItem() {
        let emitter = this.createEmitter()
        emitter.complete = () => { this.done(emitter) }
        this.items.push(emitter)
        this.counter += 1
        this.total += 1
        return emitter
    }

    registerItem(item, event) {
        switch (event) {
        case 'load':
            item.addEventListener('load', (ev) => this.onItemLoad(ev))
            break
        case 'done':
            item.addEventListener('done', (ev) => this.onItemDone(ev))
            break
        default:
            item.addEventListener('load', (ev) => this.onItemLoad(ev))
        }
        this.items.push(item)
        this.counter += 1
        this.total += 1
    }

    hide() {
        this.el.setAttribute('class', 'done')
        document.body.setAttribute('class', (document.body.getAttribute('class') || '') + ' loaded')
    }

    done(ev) {
        if (ev) {
            let pos = this.items.indexOf(ev)
            if (pos >= 0) {
                this.items.splice(pos, 1)
            }
        }
        this.counter -= 1
        if (this.counter <= 0) {
            this.hide()
        }
    }

    onItemLoad(ev) {
        this.done(ev.target)
    }

    onItemDone(ev) {
        this.done(ev.target)
    }
}

export default new DemiliePreloader()