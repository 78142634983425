/* global DOMParser */

'use strict'

export default class DemilieObjectAnimator {
    constructor(el) {
        this.el = el

        this.traj = atob(this.el.getAttribute('data-trajectory'))
        
        this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        this.svg.setAttribute('x', '0px')
        this.svg.setAttribute('y', '0px')

        this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        this.path.setAttribute('d', this.traj)
        this.svg.appendChild(this.path)
        this.pLength = this.path.getTotalLength()

        this.shoudRotate = parseFloat(this.el.getAttribute('data-rotate') || 0)
        this.rotation = 0

        this.duration = parseInt(this.el.getAttribute('data-duration') || '5000')
        this.fps = 60
        this.step = this.pLength / (this.duration / this.fps)
        this.progress = 0
    }

    setPosition() {
        let {x, y} = this.path.getPointAtLength(this.progress)

        this.el.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)'
        this.el.style['-webkit-transform'] = 'translate3d(' + x + 'px, ' + y + 'px, 0)'
        if (this.shoudRotate) {
            this.el.style.transform += ' rotate(' + this.rotation + 'deg)'
            this.el.style['-webkit-transform'] += ' rotate(' + this.rotation + 'deg)'
            this.rotation += this.shoudRotate
            this.rotation %= 360
        }

        this.progress += this.step
        if (this.progress > this.pLength) {
            this.progress -= this.pLength
        }
        window.requestAnimationFrame(() => this.setPosition())
    }

    run() {
        window.requestAnimationFrame(() => this.setPosition())
    }
}