'use strict'

export default class DemilieObjectCircleAnimator {
    constructor(el) {
        this.el = el

        this.circle = JSON.parse(this.el.getAttribute('data-circle'))
        this.pLength = 2 * Math.PI

        this.shoudSwitchZ = JSON.parse(this.el.getAttribute('data-switch-z') || '[]')
        if (this.shoudSwitchZ.length) {
            this.currentZIndex = 0
            this.zIndex = JSON.parse(this.el.getAttribute('data-z-index') || '[1000, -1000, 1000]')
        }

        this.shoudRotate = parseFloat(this.el.getAttribute('data-rotate') || 0)
        this.rotation = 0

        this.duration = parseInt(this.el.getAttribute('data-duration') || '5000')
        this.fps = 60
        this.step = this.pLength / (this.duration / this.fps)
        this.progress = this.pLength * this.circle.start
    }

    setPosition() {
        let x = Math.sin(this.progress) * this.circle.r
        let y = Math.cos(this.progress) * this.circle.r * this.circle.scale
        if (this.shoudSwitchZ.length) {
            let pC = this.progress / this.pLength
            let pos = this.shoudSwitchZ.concat([pC])
            pos.sort()
            let zI = pos.indexOf(pC)
            this.el.style.zIndex = this.zIndex[zI] || 1000
        }

        this.el.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)'
        this.el.style['-webkit-transform'] = 'translate3d(' + x + 'px, ' + y + 'px, 0)'
        if (this.shoudRotate) {
            this.el.style.transform += ' rotate(' + this.rotation + 'deg)'
            this.el.style['-webkit-transform'] += ' rotate(' + this.rotation + 'deg)'
            this.rotation += this.shoudRotate
            this.rotation %= 360
        }

        this.progress += this.step
        if (this.progress > this.pLength) {
            this.progress -= this.pLength
            this.cicle += 1
        }
        if (!this.cicle) {
            window.requestAnimationFrame(() => this.setPosition())
        }
    }

    run() {
        window.requestAnimationFrame(() => this.setPosition())
    }
}