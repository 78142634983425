'use strict'

export default class DemilieDeviceInfo {
    constructor() {
        this.isMobile = false
        this.isLandscape = false
        this.webglSupported = false

        this.detect()

        window.addEventListener('resize', () => { this.detectMobile(); this.detectRotation() })
        window.addEventListener('orientationchange', () => { this.detectRotation() })
    }

    detect() {
        this.detectMobile()
        this.detectRotation()
        this.detectWebGL()
    }

    detectMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (!this.isMobile) this.addClass('mobile')
            this.isMobile = true
        } else {
            if (this.isMobile) this.removeClass('mobile')
            this.isMobile = false
        }
    }

    detectRotation() {
        if (this.isMobile) {
            if (
                (window.orientation && (window.orientation === 90 || window.orientation === -90)) ||
                (screen.orientation && (screen.orientation.angle === 90 || screen.orientation.angle === -90))
            ){
                if (!this.isLandscape) this.addClass('landscape')
                this.isLandscape = true
            } else {
                if (this.isLandscape) this.removeClass('landscape')
                this.isLandscape = false
            }
        }
    }

    detectWebGL () {
        try {
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('webgl') || canvas.getContext('experimental-webgl') || canvas.getContext('moz-webgl') || canvas.getContext('webkit-3d')

            if (ctx) {
                this.webglSupported = true
            }
        } catch(e) {
            this.webglSupported = false
        }
        if (!this.webglSupported) this.addClass('no-webgl')
        if (this.webglSupported) this.addClass('webgl')
    }

    addClass(cn) {
        this._addClass(document.body, cn)
        this._addClass(document.documentElement, cn)
    }

    removeClass(cn) {
        this._removeClass(document.body, cn)
        this._removeClass(document.documentElement, cn)
    }

    _addClass(el, cn) {
        if (!el.getAttribute('class') || el.getAttribute('class').indexOf(cn) === -1) {
            el.setAttribute('class', (el.getAttribute('class') || '') + ' ' + cn)
        }
    }

    _removeClass(el, cn) {
        if (el.getAttribute('class') && el.getAttribute('class').indexOf(cn) !== -1) {
            el.setAttribute('class', el.getAttribute('class').replace(new RegExp('(^|\\s+)' + cn), ''))
        }
    }
}