'use strict'

import DemilieImageLoader from './image-loader'
import DemilieDeviceInfo from './device-info'
import DemiliePreloader from './preloader'

require('es6-promise').polyfill()
// import 'babel-polyfill'

var demilie = window.demilie || {}

demilie.device = new DemilieDeviceInfo()

if (!document.querySelectorAll('img').length || document.querySelectorAll('img').length === document.querySelectorAll('.logo img').length) {
    DemiliePreloader.done()
    // window.setTimeout(() => DemiliePreloader.done(), 20000)
}

demilie.toLoad = document.querySelectorAll('img[data-img]')
demilie.loadedImages = Promise.all( Array.prototype.map.call(demilie.toLoad, i => { new DemilieImageLoader(i).run() }) )

window.demilie = demilie

!(function() {
    var lastTime = 0
    var vendors = ['webkit', 'moz']
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame']
        window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame']
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback) {
            var currTime = new Date().getTime()
            var timeToCall = Math.max(0, 16 - (currTime - lastTime))
            var id = window.setTimeout(function() { callback(currTime + timeToCall) }, timeToCall)
            lastTime = currTime + timeToCall
            return id
        }

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id)
        }
}())

// https://github.com/davidchambers/Base64.js
!(function () {
    var object = typeof exports != 'undefined' ? exports : self
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

    function InvalidCharacterError(message) {
        this.message = message
    }
    InvalidCharacterError.prototype = new Error
    InvalidCharacterError.prototype.name = 'InvalidCharacterError'

    object.btoa || (
        object.btoa = function (input) {
            var str = String(input)
            for (
                var block, charCode, idx = 0, map = chars, output = '';
                str.charAt(idx | 0) || (map = '=', idx % 1);
                output += map.charAt(63 & block >> 8 - idx % 1 * 8)
            ) {
                charCode = str.charCodeAt(idx += 3/4)
                if (charCode > 0xFF) {
                    throw new InvalidCharacterError('\'btoa\' failed: The string to be encoded contains characters outside of the Latin1 range.')
                }
                block = block << 8 | charCode
            }
            return output
        }
    )

    object.atob || (
        object.atob = function (input) {
            var str = String(input).replace(/=+$/, '')
            if (str.length % 4 == 1) {
                throw new InvalidCharacterError('\'atob\' failed: The string to be decoded is not correctly encoded.')
            }
            for (
                var bc = 0, bs, buffer, idx = 0, output = '';
                (buffer = str.charAt(idx++)) !== '';
                ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
            ) {
                buffer = chars.indexOf(buffer)
            }
            return output
        }
    )
}())

!(function () {
    if (typeof window.CustomEvent === 'function') return false
    function CustomEvent (event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined }
        let evt = document.createEvent('CustomEvent')
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
        return evt
    }

    CustomEvent.prototype = window.Event.prototype

    window.CustomEvent = CustomEvent
}())