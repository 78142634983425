'use strict'

import DemiliePreloader from './preloader'

export default class DemilieCanvasLoader {
    constructor(el) {
        // el - <canvas>
        this.el = el
        this.img = this.el.getAttribute('data-img')
        this.el.removeAttribute('data-img')
        this.mask = this.el.getAttribute('data-mask')
        this.el.removeAttribute('data-mask')
        this.ctx = this.el.getContext('2d')

        this.preloader = DemiliePreloader.registerDoneItem()
    }

    getImage(src) {
        let im
        return new Promise(function (resolve, reject) {
            im = document.createElement('img')
            im.onload = () => resolve(im)
            im.onerror = reject
            im.src = src
        })
    }

    drawMask(im) {
        this.el.width = im.width
        this.el.height = im.height
        this.ctx.drawImage(im, 0, 0, im.width, im.height)
        return true
    }

    drawImg(im) {
        this.ctx.globalCompositeOperation = 'source-atop'
        this.ctx.drawImage(im, 0, 0, im.width, im.height)

        this.preloader.complete()

        return Promise.resolve()
    }

    run() {
        return (!this.img || !this.mask) ?
            Promise.resolve()
        :
            this.getImage(this.mask)
                .then(resp => this.drawMask(resp))
                .then(() => this.getImage(this.img))
                .then(resp => this.drawImg(resp))
    }
}
